<template>
  <view-item title="利润报表">
    <template #operation>
      <button-ajax type="primary" :method="operation.export.click">导出</button-ajax>
    </template>
    <en-card>
      <form-query :method="table.get" :model="form.data">
        <en-form-item label="查询日期">
          <en-date-picker
            v-model:start="form.data.startDate"
            v-model:end="form.data.endDate"
            type="daterange"
            value-format="YYYY-MM-DD"
          ></en-date-picker>
        </en-form-item>
      </form-query>
      <en-table :data="table.data" :loading="table.loading" :span-method="tableSpanMethod">
        <en-table-column label="项目" prop="name"> </en-table-column>
        <en-table-column label="收支类型" prop="type.message"> </en-table-column>
        <en-table-column label="金额" prop="amount">
          <template #default="{ row }: { row: EnocloudStatementDefinitions['ProfitsStatisticsDto'] }">{{ formatMoney(row.amount) }} </template>
        </en-table-column>
      </en-table>
    </en-card>
  </view-item>
</template>

<script lang="ts">
import { pick } from 'lodash-es'
export default factory({
  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              get: {
                action: 'GET /enocloud/statement/export/profits',
                params(params) {
                  params.payload = this.table.$ajaxParams.payload
                }
              }
            },
            async click() {
              await this.operation.export.get()
              this.store.openDownload()
            }
          }
        }
      },
      form: {
        is: 'form',
        data: {
          startDate: '',
          endDate: ''
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/statement/profits',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = pick(this.form.data, 'startDate', 'endDate')
            },
            convert(data) {
              let mainTableData = [] as any
              data.forEach((item) => {
                item.mainBusinessIncome.forEach((inner, index) => {
                  const res = {
                    id: index,
                    name: '主营业务收入',
                    amount: inner.amount,
                    type: inner.type,
                    rowspan: index ? 0 : item.mainBusinessIncome.length
                  }
                  mainTableData.push(res)
                })
                item.mainBusinessCost.forEach((inner, index) => {
                  const res = {
                    id: index,
                    name: '主营业务成本',
                    amount: inner.amount,
                    type: inner.type,
                    rowspan: index ? 0 : item.mainBusinessCost.length
                  }
                  mainTableData.push(res)
                })

                mainTableData.push({ name: '毛利润', type: {}, amount: item.profits, rowspan: 1 })
              })
              return mainTableData
            }
          }
        }
      }
    }
  },
  methods: {
    tableSpanMethod({ row, columnIndex }) {
      if (columnIndex === 0) {
        return {
          rowspan: row.rowspan,
          colspan: 1
        }
      }
    }
  },
  mounted() {
    this.table.get()
  }
})
</script>
